.box-profile {
  padding-bottom: 50px;
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */

    text-align: center;

    /* Gris Wop 1 */

    color: #3b3b3b;
  }

  h6 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 110%;
    /* or 15px */

    text-align: center;

    /* Gris Wop 1 */

    color: #3b3b3b;
  }

  img {
    max-width: 150px !important;
  }
}
