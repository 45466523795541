body {
  font-family: $font;
}
img {
  max-width: 100%;
}
a,
// button {
//   transition: all 0.5s ease-out;
// }
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.link-default {
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #3b3b3b;
  text-decoration: none !important;
}

.link-default:hover {
  color: #000;
  text-decoration: none !important;
}

/////////////////////////
///

.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerMenu {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  @media (max-width: 768px) {
    height: auto;
  }
}

.content_wrapper {
  @extend .dflex;
  width: 100%;
  height: calc(100vh - 60px);
}
header {
  @extend .dflex;
  justify-content: flex-end;
  width: 100%;
  height: 58px;
  border-bottom: 1px solid #bdbdbd;
  padding: 0 50px;
  .menu {
    width: 58px;
    height: 58px;
    @extend .dflex;
    cursor: pointer;
  }
}
.menu_container {
  position: absolute;
  padding-left: 15%;
  width: 100%;
  height: 100%;
  background-color: #3ae1c3;
  color: #fff;
  z-index: 4;
  @extend .dflex;
  flex-direction: column;
  align-items: flex-start;
  a.linkMenu {
    display: inline-flex;
    color: #000;
    text-decoration: none;
    font-weight: 300;
    overflow: hidden;
    font-size: 80px;
    @media (max-width: 768px) {
      font-size: 30px;
    }
    text-align: left;
    + a.linkMenu {
      margin-top: 1vw;
    }
  }

  a.linkMenu:active {
    color: #fff;
  }

  .btn_close {
    @extend .dflex;
    width: 28px;
    height: 28px;
    background-color: #fff;
    color: #3ae1c3;
    border-radius: 28px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    font-size: 18px;
  }

  .header_menu {
    @extend .dflex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 80px;
    background-color: transparent;
    padding-left: 15%;
    padding-right: 15%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    cursor: pointer;
    font-size: 18px;
  }
}
.carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  margin: 0 10px !important;
  border-radius: 100%;
  background-color: #3ae1c3 !important;
}

.linkNavHeader {
  background-image: url("../assets/arrowBlack.png");
  background-position: -50px 50px;
  background-repeat: no-repeat;
  color: #000;
}

.linkNavHeader:hover {
  background-image: url("../assets/arrowBlack.png");
  background-position: 0 50px;
  background-repeat: no-repeat;
  color: #000;
  padding-left: 60px;
}

.linkNavHeader:active {
  background-image: url("../assets/arrowWhite.png");
  background-position: 0 50px;
  background-repeat: no-repeat;
  color: #fff !important;
  padding-left: 60px;
}

.accordionWop {
  .accordion-item {
    border: 0 !important;
    outline: transparent !important;
    box-shadow: none !important;

    h5 {
      font-weight: 300 !important;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent !important;
  }

  .accordion-button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
.project-image,
.hover-image,
.mobile-image {
  width: 100%;
  display: block;
  margin-bottom: 24px;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.image-container:hover .hover-image {
  opacity: 1;
  cursor: pointer !important;
}
.image-container:hover .mobile-image {
  opacity: 0;
  cursor: pointer !important;
}

.mobile-image {
  display: none;
}

@media (max-width: 768px) {
  .mobile-image {
    display: block;
  }
}

.linkNext {
  color: #3ae1c3;
  font-size: 22px;
  text-decoration: underline !important;

  @media (max-width: 768px) {
    font-size: 17px;
    padding: 10px;
  }

  &.rightN {
    text-align: right;
    margin-right: 5px;
  }
}

.linkNext:hover {
  color: #3ae1c3;

  text-decoration: underline !important;
}

/* Project_BDO.css */
.video-frame {
  width: 100%;
  height: 720px;
}

@media (max-width: 768px) {
  .video-frame {
    height: 300px; /* Adjust the height as needed for mobile screens */
  }
}

.tituloProject {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.subtituloProject {
  font-size: 20px !important;
  font-weight: 400 !important;
}

form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
  border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-bottom: 1px solid #00bfa5;
}

textarea:focus {
  border: 1px solid #00bfa5;
}

.textareanew {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
  border-radius: 0;
}

button[type="submit"] {
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #00bfa5;
  background-color: #ffffff;
  border: 2px solid #00bfa5;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-color: #00bfa5 !important;
}

button[type="submit"]:hover {
  background-color: #00bfa5;
  color: #ffffff;
  border-color: #00bfa5 !important;
}

.errorspan {
  font-size: 11px;
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  display: inline-block;
  font-weight: 300;
}
