@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap");

$font: "Lexend Deca", sans-serif;

// FONT SIZES
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$fs18: 18px;
$fs20: 20px;
$fs22: 22px;
$fs23: 23px;
$fs24: 24px;
$fs26: 26px;
$fs28: 28px;
$fs32: 32px;
$fs40: 40px;
$fs44: 44px;
$fs48: 48px;
$fs64: 64px;

.lightTxt {
  font-weight: 300 !important;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs23 {
  font-size: 23px;
}

.fs24 {
  font-size: 24px;
}

.fs26 {
  font-size: 26px;
}

.fs28 {
  font-size: 28px;
}

.fs30 {
  font-size: 30px;
}
