.boxWhoWheAre {
  padding-top: 120px;
  padding-bottom: 120px;

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.list-what-we-do {
  li {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 143.5%;
    color: #3ae1c3;
  }
}
