.container_projects {
  background-image: url("../../assets/wop_outline.png");
  background-repeat: no-repeat;
  background-position: left center;
  margin: 0 auto !important;

  p {
    font-weight: 300;
  }
}

.container_brands {
  background-color: #929798;

  h2 {
    font-style: normal;
    font-weight: 100;
    font-size: 80px;
    line-height: 100%;
    /* identical to box height, or 80px */

    text-align: center;
    text-transform: lowercase;

    color: #ffffff;

    @media (max-width: 767px) {
      font-size: 40px;
    }
  }
}

.footer {
  background: #000;
  ul {
    li {
      color: #ffffff;
      font-weight: 300;
    }
  }
}

.link-menu {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 114%;
  color: #000000;
  text-decoration: none !important;
}

.link-menu:hover {
  color: #fff;
  text-decoration: none !important;
}

.boxImgProject {
  width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  -webkit-transition: all 0.9s ease !important;
  -moz-transition: all 0.9s ease !important;
  -ms-transition: all 0.9s ease !important;
  -o-transition: all 0.9s ease !important;
  transition: all 0.9s ease !important;

  &.benomad {
    background-image: url("../../assets/projects/new/seleccionador_benomad_inactivo.png");

    &.active {
      background-image: url("../../assets/projects/new/seleccionador_benomad_activo.png");
    }
  }

  &.benomad:hover {
    background-image: url("../../assets/projects/new/seleccionador_benomad_activo.png");
  }

  &.flip {
    background-image: url("../../assets/projects/new/seleccionador_flip_inactivo.png");
    &.active {
      background-image: url("../../assets/projects/new/seleccionador_flip_activo.png");
    }
  }

  &.flip:hover {
    background-image: url("../../assets/projects/new/seleccionador_flip_activo.png");
  }

  &.trenes {
    background-image: url("../../assets/projects/new/seleccionador_trenes_inactivo.png");
    &.active {
      background-image: url("../../assets/projects/new/seleccionador_trenes_activo.png");
    }
  }

  &.trenes:hover {
    background-image: url("../../assets/projects/new/seleccionador_trenes_activo.png");
  }

  &.zurich {
    background-image: url("../../assets/projects/new/seleccionador_zurich_inactivo.png");
    &.active {
      background-image: url("../../assets/projects/new/seleccionador_zurich_activo.png");
    }
  }

  &.zurich:hover {
    background-image: url("../../assets/projects/new/seleccionador_zurich_activo.png");
  }
}

.boxImgProject:hover {
  -webkit-transition: all 0.9s ease !important;
  -moz-transition: all 0.9s ease !important;
  -ms-transition: all 0.9s ease !important;
  -o-transition: all 0.9s ease !important;
  transition: all 0.9s ease !important;
}

.lang-selected {
  color: #3ae1c3 !important;
}

.modal-video-portada {
  background-color: transparent !important;
  border: none !important;
  .modal-body,
  .modal-content,
  .modal-dialog {
    background-color: transparent !important;
    border: none !important;
  }
}

.open-navbar {
  .nav-link {
    color: white !important;
  }
  .nav-link {
    &.lang-selected {
      color: black !important;
    }
  }
}

.txt26 {
  font-size: 26px;
}
.txt20 {
  font-size: 20px;
}

/* GlobalStyles.css */

.image-container img {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease-in-out; /* Smooth transition for image changes */
  display: block;
}

.image-container:hover img {
  opacity: 0.8; /* Slight dim effect on hover */
}
