.box-contact {
  background: url("../../assets/contact/bg.png");
  background-repeat: no-repeat;
  background-position: top right;

  .contenidoHeadContact {
    min-height: 750px;
  }

  @media (max-width: 767px) {
    background: none;
    height: auto;
  }

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 80px;
    line-height: 90%;
    /* or 72px */

    color: #000000;

    span {
      color: #03caa6;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    color: #000000;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    color: #000000;
    padding-top: 20px;
  }

  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    color: #000000;
  }

  a {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    color: #000000;
    text-decoration: none !important;
  }
}
